window.Client = {

    init: function () {
        // Do something on initialization/
    },

    onRender: function (screenId) {
        // Do something when the flow renders
        
        // log screen id
        console.log("Current screen:", screenId);

        // Trigger conversion if on 'videotelefonat' page
        if (screenId == 'id-835c2776') {
            gtag('event', 'conversion', {
                'send_to': 'AW-797559756/cJQcCIqjgq0ZEMyXp_wC'
            });
        };

    }
};